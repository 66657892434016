@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .content {
    height: calc(100vh - 3rem);
    overflow: auto;
  }
  @media (min-width: 768px) {
    .content {
      height: calc(100vh - 4rem);
      overflow: auto;
    }
  }
  .hoist-sequence-content {
    height: calc(100vh - 18rem);
  }
}
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #d8d8d8;
  border-radius: 10px;
  width: 8px;
}
::-webkit-scrollbar:horizontal {
  height: 6px;
}
