.react-calendar-timeline {
  /* this is set to control the header's order to match the design */
  display: flex;
  flex-flow: column;
  width: 100%;
}

.react-calendar-timeline .rct-header-root {
  background-color: unset;
  border: unset;
  order: 2;
}

.react-calendar-timeline__horizontal-line {
  height: 50px;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: unset;
}

.react-calendar-timeline .rct-calendar-header {
  border: unset;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: unset;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: unset;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: unset;
}

.rct-scroll {
  width: 100% !important;
}
